import {
    ACCEPT_CGU,
    CHANGE_ENEDIS_CONSENT,
    CHANGE_PASSWORD,
    GET_ACCOUNT_INFO,
    CREATE_CONFIG,
    EDIT_CONFIG,
    GET_APPMAP,
    GET_CDC_DATA,
    GET_CONFIGS,
    GET_CONSUMER_CDC_DATA,
    GET_EXCESS_BUYERS,
    GET_DISTRIBUTION_DATA,
    GET_METRICS_DATA,
    GET_OPERATION_PARTICIPANTS,
    GET_OPERATIONS,
    GET_PARTICIPANT_METRICS_DATA,
    GET_PRODUCER_CDC_DATA,
    GET_RANGE,
    GET_USER,
    INIT_ACCOUNT_INFO,
    INIT_CRUMBS,
    INIT_DRAWER,
    INIT_IS_QUERYING_DATA_IN_RANGE,
    INIT_MEASUREMENT,
    INIT_OPERATIONS,
    INIT_RANGE,
    INIT_ROUTES,
    INIT_SELECTED_DATES,
    INIT_SELECTED_OPERATION,
    INIT_SORTING,
    INIT_USER,
    LOG_IN,
    LOG_OUT,
    RANGE_LOADING,
    REMOVE_CONFIG,
    SET_AC_TYPE,
    SET_ACCOUNT_INFO,
    UPDATE_PARTICIPANT,
    SET_APPMAP,
    SET_CDC_DATA,
    SET_CDC_LOADING,
    SET_CGU,
    SET_CONFIGS,
    SET_CRUMBS,
    SET_DISTRIBUTION_DATA,
    SET_DISTRIBUTION_LOADING,
    SET_EMISSION,
    SET_ENEDIS_CONSENT_POPUP,
    SET_ERROR,
    SET_EXCESS_BUYERS,
    SET_EXCESS_CONFIGS,
    SET_INITIALIZED,
    SET_IS_AUTHENTICATING,
    SET_IS_QUERYING_DATA_IN_RANGE,
    SET_ITEM,
    SET_METRICS_DATA,
    SET_METRICS_LOADING,
    SET_OPERATIONS,
    SET_PARTICIPANT_TYPE,
    SET_RANGE,
    SET_ROUTES,
    SET_SEARCH,
    SET_SELECTED_DATES,
    SET_SELECTED_OPERATION,
    SET_SORTING,
    SET_USER,
    UPDATE_OPERATION,
    UPDATE_PRESIGNED_LOGOS,
    GET_PRESIGNED_BILLING_LOGO,
    SET_PRESIGNED_BILLING_LOGO,
    DOWNLOAD_BILL_DOCUMENTS,
    SET_PARTICIPANTS_BILLS,
    GET_BILLS,
    SET_EXCESS_BILLS,
    UPDATE_BILLS,
    EDIT_EXCESS_BILL,
    UPLOAD_EXCESS_CONTRACT,
    UPLOAD_EXCESS_TEMPLATE,
    DOWNLOAD_BILLING_STATUS,
    GET_CONSUMPTION_AND_ECONOMY,
    SET_CONSUMPTION_AND_ECONOMY,
    SET_START_DATE,
    SET_END_DATE,
    SET_CONTRACT_TYPE,
    SET_BASE_KWH_PRICE,
    SET_PEAK_KWH_PRICE,
    SET_OFF_PEAK_KWH_PRICE,
    SET_WINTER_PEAK_KWH_PRICE,
    SET_WINTER_OFF_PEAK_KWH_PRICE,
    SET_SUMMER_PEAK_KWH_PRICE,
    SET_SUMMER_OFF_PEAK_KWH_PRICE,
    SET_IS_ON_TRV,
    SET_CONSUMER_TYPE,
    SET_OFF_PEAK_HOUR_START,
    SET_PEAK_HOUR_START,
    APPLY_CONFIG,
    INIT_PARTICIPANT_FORM,
    SET_CONTRACT_TYPE_FROM_PARTICIPANT_DATA,
    GET_PRICES_CONFIGS,
    SELECT_PARTICIPANT,
    UNSELECT_PARTICIPANT,
    SELECT_ALL_PARTICIPANTS,
    UNSELECT_ALL_PARTICIPANTS,
    SET_CONSUMPTION_AND_ECONOMY_LOADING,
    GET_TRV_PRICE,
    SET_TRV_PRICE,
    INIT_GLOBAL_FORM,
    INIT_CONSUMPTION_AND_ECONOMY,
    SEND_COMMUNICATION,
    SET_COMMUNICATION_RESULT,
    SET_DOWNLOAD_LOADING,
} from './reducers/constants';

// account actions
export function getAccountInfo() {
    return { type: GET_ACCOUNT_INFO };
}

export function initAccountInfo() {
    return { type: INIT_ACCOUNT_INFO };
}

export function setAccountInfo(accountInfo) {
    return { type: SET_ACCOUNT_INFO, accountInfo };
}

// operation actions
export function getOperations(role) {
    return { type: GET_OPERATIONS, role };
}

export function setOperations(operations) {
    return { type: SET_OPERATIONS, operations };
}

export function setSelectedOperation(selectedOperation) {
    return { type: SET_SELECTED_OPERATION, selectedOperation };
}

export function initSelectedOperation() {
    return { type: INIT_SELECTED_OPERATION };
}

export function initOperations() {
    return { type: INIT_OPERATIONS };
}

export function getOperationParticipants(operation, dateFrom, dateTo) {
    return { type: GET_OPERATION_PARTICIPANTS, operation, dateFrom, dateTo };
}

export function updateOperation({ operation, formData }) {
    return { type: UPDATE_OPERATION, operation, formData };
}

export function setSearch(search) {
    return { type: SET_SEARCH, search };
}

export function setSorting(sorting) {
    return { type: SET_SORTING, sorting };
}

export function initSorting() {
    return { type: INIT_SORTING };
}

export function setParticipantType(participantType) {
    return { type: SET_PARTICIPANT_TYPE, participantType };
}

export function updatePresignedLogos(operations) {
    return { type: UPDATE_PRESIGNED_LOGOS, operations };
}

// measurement actions
export function setSelectedDates(selectedDates) {
    return { type: SET_SELECTED_DATES, selectedDates };
}

export function setIsQueryingDataInRange(isQueryingDataInRange) {
    return { type: SET_IS_QUERYING_DATA_IN_RANGE, isQueryingDataInRange };
}

export function setRange(range) {
    return { type: SET_RANGE, range };
}

export function getRange(participantId) {
    return { type: GET_RANGE, participantId };
}

export function initSelectedDates() {
    return { type: INIT_SELECTED_DATES };
}

export function initIsQueryingDataInRange() {
    return { type: INIT_IS_QUERYING_DATA_IN_RANGE };
}

export function initRange() {
    return { type: INIT_RANGE };
}

export function setRangeLoading(loading) {
    return { type: RANGE_LOADING, loading };
}

export function getCDC() {
    return { type: GET_CDC_DATA };
}

export function setCDC(cdc) {
    return { type: SET_CDC_DATA, cdc };
}

export function getMetrics() {
    return { type: GET_METRICS_DATA };
}

export function setMetrics(metrics) {
    return { type: SET_METRICS_DATA, metrics };
}

export function setMetricsLoading(loading) {
    return { type: SET_METRICS_LOADING, loading };
}

export function setCDCLoading(loading) {
    return { type: SET_CDC_LOADING, loading };
}

export function initMeasurement() {
    return { type: INIT_MEASUREMENT };
}

export function getConsumerCDC(consumerId) {
    return { type: GET_CONSUMER_CDC_DATA, consumerId };
}

export function getProducerCDC(producerId) {
    return { type: GET_PRODUCER_CDC_DATA, producerId };
}

export function getParticipantMetrics({ participantId, isConsumer }) {
    return { type: GET_PARTICIPANT_METRICS_DATA, participantId, isConsumer };
}

export function getDistribution() {
    return { type: GET_DISTRIBUTION_DATA };
}

export function setDistribution(distribution) {
    return { type: SET_DISTRIBUTION_DATA, distribution };
}

export function setDistributionLoading(loading) {
    return { type: SET_DISTRIBUTION_LOADING, loading };
}

// route actions
export function setCrumbs(crumbs) {
    return { type: SET_CRUMBS, crumbs };
}

export function initCrumbs() {
    return { type: INIT_CRUMBS };
}

export function setRoutes(routes) {
    return { type: SET_ROUTES, routes };
}

export function initRoutes() {
    return { type: INIT_ROUTES };
}

// drawer actions
export function setSelectedItem(item) {
    return { type: SET_ITEM, item };
}

export function initDrawer() {
    return { type: INIT_DRAWER };
}

//authentication actions
export function getUser(history) {
    return { type: GET_USER, history };
}

export function setUser(user) {
    return { type: SET_USER, user };
}

export function initUser() {
    return { type: INIT_USER };
}

export function setInitialized(initialized) {
    return { type: SET_INITIALIZED, initialized };
}

export function logOut(history) {
    return { type: LOG_OUT, history };
}

export function logIn({ history, email, password }) {
    return { type: LOG_IN, history, email, password };
}

export function setCgu(cgu, history) {
    return { type: SET_CGU, cgu, history };
}

export function acceptCgu(cguId, history) {
    return { type: ACCEPT_CGU, cguId, history };
}

export function setEnedisConsentPopup(enedisConsentPopup) {
    return { type: SET_ENEDIS_CONSENT_POPUP, enedisConsentPopup };
}

export function changeEnedisConsent(userId, consent) {
    return { type: CHANGE_ENEDIS_CONSENT, userId, consent };
}

export function changePassword(oldPassword, newPassword, history) {
    return { type: CHANGE_PASSWORD, oldPassword, newPassword, history };
}

export function setIsAuthenticating(isAuthenticating) {
    return { type: SET_IS_AUTHENTICATING, isAuthenticating };
}

// participant actions
export function updateParticipant(participant, newParticipant, formData) {
    return { type: UPDATE_PARTICIPANT, participant, newParticipant, formData };
}

export function getPresignedBillingLogo(participant) {
    return { type: GET_PRESIGNED_BILLING_LOGO, participant };
}

export function setPresignedBillingLogo(presignedBillingLogo) {
    return { type: SET_PRESIGNED_BILLING_LOGO, presignedBillingLogo };
}

//functionalities actions
export function setACType(ACType) {
    return { type: SET_AC_TYPE, ACType };
}

export function setEmission(emission) {
    return { type: SET_EMISSION, emission };
}

// billing actions
export function downloadBillDocuments(bills, period) {
    return { type: DOWNLOAD_BILL_DOCUMENTS, bills, period };
}

// billing actions
export function setDownloadLoading(loading) {
    return { type: SET_DOWNLOAD_LOADING, loading };
}

export function getConfigs(configType) {
    return { type: GET_CONFIGS, configType };
}

export function createConfig(config, configType) {
    return { type: CREATE_CONFIG, config, configType };
}

export function setParticipantsConfigs(configs) {
    return { type: SET_CONFIGS, configs };
}

export function setExcessConfigs(configs) {
    return { type: SET_EXCESS_CONFIGS, configs };
}

export function deleteConfig(configId, configType) {
    return { type: REMOVE_CONFIG, configId, configType };
}

export function editConfig(config, configType, isNewConfig) {
    return { type: EDIT_CONFIG, config, configType, isNewConfig };
}

export function getExcessBuyers() {
    return { type: GET_EXCESS_BUYERS };
}

export function setExcessBuyers(excessBuyers) {
    return { type: SET_EXCESS_BUYERS, excessBuyers };
}

export function getBills(configType) {
    return { type: GET_BILLS, configType };
}

export function updateBills(bills) {
    return { type: UPDATE_BILLS, bills };
}

export function setParticipantsBills(bills) {
    return { type: SET_PARTICIPANTS_BILLS, bills };
}

export function setExcessBills(bills) {
    return { type: SET_EXCESS_BILLS, bills };
}

export function uploadExcessContract({ contract, producerId }) {
    return { type: UPLOAD_EXCESS_CONTRACT, contract, producerId };
}

export function uploadExcessTemplate({ template, producerId }) {
    return { type: UPLOAD_EXCESS_TEMPLATE, template, producerId };
}

export function editExcessBill({ startDate, endDate, producerId }) {
    return { type: EDIT_EXCESS_BILL, startDate, endDate, producerId };
}

export function downloadBillingStatus(producer, billingPeriodEnd) {
    return { type: DOWNLOAD_BILLING_STATUS, producer, billingPeriodEnd };
}

// error actions
export function setError({ status, message, variant }) {
    return { type: SET_ERROR, status, message, variant };
}

export function initError() {
    return { type: SET_ERROR };
}

// navigation actions
export function getAppMap() {
    return { type: GET_APPMAP };
}

export function setAppMap(appMap) {
    return { type: SET_APPMAP, appMap };
}

// billCheck actions
export function getConsumptionAndEconomy() {
    return { type: GET_CONSUMPTION_AND_ECONOMY };
}

export function setConsumptionAndEconomy(consumptionAndEconomy) {
    return { type: SET_CONSUMPTION_AND_ECONOMY, consumptionAndEconomy };
}

export function initConsumptionAndEconomy() {
    return { type: INIT_CONSUMPTION_AND_ECONOMY };
}

export function setConsumptionAndEconomyLoading(loading) {
    return { type: SET_CONSUMPTION_AND_ECONOMY_LOADING, loading };
}

export function setStartDate(startDate) {
    return { type: SET_START_DATE, startDate };
}

export function setEndDate(endDate) {
    return { type: SET_END_DATE, endDate };
}

export function setContractType(contractType) {
    return { type: SET_CONTRACT_TYPE, contractType };
}

export function setConsumerType(consumerType) {
    return { type: SET_CONSUMER_TYPE, consumerType };
}

export function setBasekWhPrice(basekWhPrice) {
    return { type: SET_BASE_KWH_PRICE, basekWhPrice };
}

export function setPeakkWhPrice(peakkWhPrice) {
    return { type: SET_PEAK_KWH_PRICE, peakkWhPrice };
}

export function setOffPeakkWhPrice(offPeakkWhPrice) {
    return { type: SET_OFF_PEAK_KWH_PRICE, offPeakkWhPrice };
}

export function setWinterPeakkWhPrice(winterPeakkWhPrice) {
    return { type: SET_WINTER_PEAK_KWH_PRICE, winterPeakkWhPrice };
}

export function setWinterOffPeakkWhPrice(winterOffPeakkWhPrice) {
    return { type: SET_WINTER_OFF_PEAK_KWH_PRICE, winterOffPeakkWhPrice };
}

export function setSummerPeakkWhPrice(summerPeakkWhPrice) {
    return { type: SET_SUMMER_PEAK_KWH_PRICE, summerPeakkWhPrice };
}

export function setSummerOffPeakkWhPrice(summerOffPeakkWhPrice) {
    return { type: SET_SUMMER_OFF_PEAK_KWH_PRICE, summerOffPeakkWhPrice };
}

export function setIsOnTRV(isOnTRV) {
    return { type: SET_IS_ON_TRV, isOnTRV };
}

export function setPeakHourStart(peakHourStart) {
    return { type: SET_PEAK_HOUR_START, peakHourStart };
}

export function setOffPeakHourStart(offPeakHourStart) {
    return { type: SET_OFF_PEAK_HOUR_START, offPeakHourStart };
}

export function applyConfig(participants, participantForm) {
    return { type: APPLY_CONFIG, participants, participantForm };
}

export function initParticipantForm(participantForm) {
    return { type: INIT_PARTICIPANT_FORM, participantForm };
}

export function initGlobalForm() {
    return { type: INIT_GLOBAL_FORM };
}

export function setContractTypeFromParticipantData(id, consumerType) {
    return { type: SET_CONTRACT_TYPE_FROM_PARTICIPANT_DATA, consumerType, id };
}

export function getPricesConfigs() {
    return { type: GET_PRICES_CONFIGS };
}

export function selectParticipant(id) {
    return { type: SELECT_PARTICIPANT, id };
}

export function selectAllParticipants(ids) {
    return { type: SELECT_ALL_PARTICIPANTS, ids };
}

export function unselectAllParticipants() {
    return { type: UNSELECT_ALL_PARTICIPANTS };
}

export function unSelectParticipant(id) {
    return { type: UNSELECT_PARTICIPANT, id };
}

export function getTRVPrice() {
    return { type: GET_TRV_PRICE };
}

export function setTRVPrice(trvPrice) {
    return { type: SET_TRV_PRICE, trvPrice };
}

// communication actions
export function sendCommunication({ subject, body, recipients }) {
    return { type: SEND_COMMUNICATION, subject, body, recipients };
}

export function setCommunicationResult({ message, messageType }) {
    return { type: SET_COMMUNICATION_RESULT, message, messageType };
}
