import client from '../../libs/client';

export default class MeasurementGateway {
    error;

    setError = (error) => {
        this.error = error;
    };

    getDateRanges = async (operationId, participantIds) => {
        let url;
        const params = {};
        if (participantIds && participantIds.length === 1) {
            url = `/participants/${participantIds[0]}/measurements/date-ranges`;
        } else {
            url = `/operations/${operationId}/measurements/date-ranges`;
            params.participantIds = participantIds;
        }
        const { data: dates } = await client.get(url, { params });
        return dates;
    };

    getConsumptionData = async (operationId, from, to) => {
        const { data } = await this.getGlobalLoad(
            operationId,
            from,
            to,
            'Conso'
        );
        return data;
    };

    getConsumerConsumptionData = async (consumerId, from, to) => {
        const { data } = await this.getConsumerLoad(
            consumerId,
            from,
            to,
            'Conso'
        );
        return data;
    };

    getProductionData = async (operationId, from, to) => {
        const { data } = await this.getGlobalLoad(
            operationId,
            from,
            to,
            'Prod'
        );
        return data;
    };

    getProducerProductionData = async (producerId, from, to) => {
        const { data } = await this.getProducerLoad(
            producerId,
            from,
            to,
            'Prod'
        );
        return data;
    };

    getAutoConsumptionData = async (operationId, from, to) => {
        const { data } = await this.getGlobalLoad(
            operationId,
            from,
            to,
            'Autoconso'
        );
        return data;
    };

    getConsumerAutoConsumptionData = async (consumerId, from, to) => {
        const { data } = await this.getConsumerLoad(
            consumerId,
            from,
            to,
            'Autoconso'
        );
        return data;
    };

    getProducerAutoConsumptionData = async (producerId, from, to) => {
        const { data } = await this.getProducerLoad(
            producerId,
            from,
            to,
            'Autoconso'
        );
        return data;
    };

    getSurplusData = async (operationId, from, to) => {
        const { data } = await this.getGlobalLoad(
            operationId,
            from,
            to,
            'Surplus'
        );
        return data;
    };

    getExternalProduction = async (operationId, from, to) => {
        const { data } = await this.getGlobalLoad(
            operationId,
            from,
            to,
            'ProdExt'
        );
        return data;
    };

    getProducerExternalProductionData = async (producerId, from, to) => {
        const { data } = await this.getProducerLoad(
            producerId,
            from,
            to,
            'ProdExt'
        );
        return data;
    };

    getExternalConsumption = async (operationId, from, to) => {
        const { data } = await this.getGlobalLoad(
            operationId,
            from,
            to,
            'ConsExt'
        );
        return data;
    };

    getProducerExternalConsumptionData = async (producerId, from, to) => {
        const { data } = await this.getProducerLoad(
            producerId,
            from,
            to,
            'ConsExt'
        );
        return data;
    };

    getMetrics = async (operationId, from, to) => {
        const { data } = await client.get(
            `operations/${operationId}/measurements/metrics?scope=Global&dateFrom=${from.toISOString()}&dateTo=${to.toISOString()}`
        );
        return data;
    };

    getConsumerMetrics = async (consumerId, from, to) => {
        const { data } = await client.get(
            `participants/${consumerId}/measurements/metrics?scope=Consommateur&dateFrom=${from.toISOString()}&dateTo=${to.toISOString()}`
        );
        return data;
    };

    getProducerMetrics = async (producerId, from, to) => {
        const { data } = await client.get(
            `participants/${producerId}/measurements/metrics?scope=Producteur&dateFrom=${from.toISOString()}&dateTo=${to.toISOString()}`
        );
        return data;
    };

    arrayToDistributionMap = (array) => {
        return array.reduce((acc, curr) => {
            acc.set(curr[0], new Map(curr[1]));
            return acc;
        }, new Map());
    };

    getDistribution = async (operationId, dateFrom, dateTo) => {
        const { data } = await client.get(
            `/operations/${operationId}/measurements/distribution?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`
        );
        return this.arrayToDistributionMap(data);
    };

    getGlobalLoad(operationId, dateFrom, dateTo, nature) {
        return client.get(
            `operations/${operationId}/measurements/load-profile?scope=Global&nature=${nature}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`
        );
    }

    getConsumerLoad(consumerId, dateFrom, dateTo, nature) {
        return client.get(
            `participants/${consumerId}/measurements/load-profile?scope=Consommateur&nature=${nature}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`
        );
    }

    getProducerLoad(producerId, dateFrom, dateTo, nature) {
        return client.get(
            `participants/${producerId}/measurements/load-profile?scope=Producteur&nature=${nature}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`
        );
    }
}
