import client from '../../libs/client';

export default class BillCheckGateway {
    getEconomyByConsumerIdAndPeriod = async (
        id,
        startDate,
        endDate,
        consumerType,
        contractType,
        isOnTRV,
        basekWhPrice,
        peakkWhPrice,
        offPeakkWhPrice,
        summerPeakkWhPrice,
        summerOffPeakkWhPrice,
        winterPeakkWhPrice,
        winterOffPeakkWhPrice,
        peakStart,
        offPeakStart
    ) => {
        try {
            const { data } = await client.get(
                `/participants/${id}/economy/pmo`,
                {
                    params: {
                        startDate,
                        endDate,
                        contractType,
                        consumerType,
                        basekWhPrice,
                        peakkWhPrice,
                        offPeakkWhPrice,
                        summerPeakkWhPrice,
                        summerOffPeakkWhPrice,
                        winterPeakkWhPrice,
                        winterOffPeakkWhPrice,
                        peakStart,
                        offPeakStart,
                        TRV: isOnTRV,
                    },
                }
            );
            return data.economy;
        } catch (err) {
            if (
                err.response?.status === 400 &&
                err.response?.data?.invalidFields?.length
            ) {
                return null;
            }
            throw err;
        }
    };

    getConsumptionByConsumerIdAndPeriod = async (
        consumerId,
        startDate,
        endDate
    ) => {
        const { data } = await client.get(
            `/participants/${consumerId}/measurements/metrics`,
            {
                params: {
                    dateFrom: startDate,
                    dateTo: endDate,
                    scope: 'Consommateur',
                },
            }
        );
        return {
            selfConsumed: parseFloat(data.selfConsumed),
            globalConsumption: parseFloat(data.globalConsumption),
        };
    };

    getPriceConfig = async (consumerId) => {
        const { data } = await client.get(
            `/participants/${consumerId}/economy/pmo-price-config`
        );
        if (data) {
            data.isOnTRV = data.TRV;
            data.peakHourStart = data.peakStart;
            data.offPeakHourStart = data.offPeakStart;
            delete data.TRV;
            delete data.peakStart;
            delete data.offPeakStart;
        }
        return data;
    };

    getTRVPrice = async () => {
        const { data } = await client.get(`/trv-prices/last`);
        return data;
    };
}
