import { all, fork } from 'redux-saga/effects';
import operationsSaga from '../usecases/operation/operationSaga';
import { adminSaga } from 'react-admin';
import authenticationSaga from '../usecases/authentication/authenticationSaga';
import mesurementSaga from '../usecases/measurement/mesurementSaga';
import billingSaga from '../usecases/billing/billingSaga';
import participantSaga from '../usecases/participant/participantSaga';
import accountSaga from '../usecases/account/accountSaga';
import navigationSaga from '../usecases/navigation/navigationSaga';
import billCheckSaga from '../usecases/billCheck/billCheckSaga';
import communicationSaga from '../usecases/communication/communicationSaga';

const sagas = [
    ...operationsSaga,
    ...authenticationSaga,
    ...mesurementSaga,
    ...accountSaga,
    ...billingSaga,
    ...participantSaga,
    ...navigationSaga,
    ...billCheckSaga,
    ...communicationSaga,
];

export default function rootSaga(dataProvider, authProvider) {
    return function* () {
        yield all(
            [
                //admin saga
                adminSaga(dataProvider, authProvider),
                ...sagas,
            ].map(fork)
        );
    };
}
