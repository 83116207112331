import client from '../../libs/client';
import axios from 'axios';

export default class OperationGateway {
    error;

    setError = (error) => {
        this.error = error;
    };

    getOrganizerByJwt = async () => {
        const { data: organizer } = await client.get('/organizers/by-jwt');
        return organizer;
    };

    getOperations = async (operationsIds) => {
        const operations = await Promise.all(
            operationsIds.map((opId) => client.get(`/operations/${opId}`))
        );
        return operations.map((op) => op.data);
    };

    getObserverOperations = async (detailed) => {
        const { data: operations } = await client.get(
            `/observers/by-jwt/operations?detailed=${detailed}`
        );
        return operations;
    };

    getOrganizerOperations = async (organizerId, detailed) => {
        const { data: operations } = await client.get(
            `/organizers/${organizerId}/operations?detailed=${detailed}`
        );
        return operations;
    };

    getParticipantsByJwt = async () => {
        const { data: participants } = await client.get(`/participants/by-jwt`);
        return participants;
    };

    getOperationParticipants = async (operationId, from, to) => {
        const dateFrom = from ? from.toISOString() : '';
        const dateTo = to ? to.toISOString() : '';
        const { data: participants } = await client.get(
            `/operations/${operationId}/participants?dateFrom=${dateFrom}&dateTo=${dateTo}`
        );
        return participants;
    };

    getOperationObservers = async (operationId) => {
        const { data: observers } = await client.get(
            `/operations/${operationId}/observers`
        );
        return observers;
    };

    updateOperation = async (operation) => {
        const { data: updatedOperation } = await client.put(
            `/operations/${operation.id}`,
            operation
        );
        return updatedOperation;
    };

    uploadLogo = async (operation, formData) => {
        const { data } = await client.post(
            `operations/${operation.id}/upload-logo`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return data;
    };

    getPresignedLogo = async (operation) => {
        if (operation.theme && operation.theme.logo) {
            const {
                data: { url },
            } = await client.get(`/operations/${operation.id}/presigned-logo`);
            try {
                const { data: logo } = await axios(url, {
                    method: 'GET',
                    responseType: 'blob',
                });
                operation.presignedLogo = URL.createObjectURL(logo);
            } catch (err) {
                console.log(err);
            }
        }
    };

    getAllOperations = async () => {
        const { data: operations } = await client.get(`/operations`);
        return operations;
    };
}
