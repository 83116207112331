import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
    getOperationParticipants,
    setError,
    setPresignedBillingLogo,
} from '../../redux/actions';
import {
    UPDATE_PARTICIPANT,
    GET_PRESIGNED_BILLING_LOGO,
} from '../../redux/reducers/constants';
import { selectSelectedOperation } from '../../redux/selectors/operationSelectors';

function* updateParticipant(action) {
    try {
        const { participant, newParticipant, formData } = action;
        const operation = yield select(selectSelectedOperation);
        const participantGateway = yield getContext('participantGateway');
        let billingLogoPath;
        if (formData) {
            billingLogoPath = yield call(
                participantGateway.uploadBillingLogo,
                participant,
                formData
            );
        }
        yield call(
            participantGateway.updateParticipant,
            participant,
            newParticipant,
            billingLogoPath
        );
        yield put(getOperationParticipants(operation));
    } catch (error) {
        console.log('error on updateParticipant');
        yield put(
            setError({
                status: error?.response?.status,
                message:
                    'Nous ne pouvons pas créer un compte utilisateur avec cette adresse. Veuillez contacter le support à support@enogrid.com ou essayer avec une autre adresse e-mail.',
                variant: 'error',
            })
        );
        console.error(error);
    }
}

function* getPresignedBillingLogo(action) {
    try {
        const { participant } = action;
        const participantGateway = yield getContext('participantGateway');
        const presignedBillingLogo = yield call(
            participantGateway.getPresignedBillingLogo,
            participant
        );
        yield put(setPresignedBillingLogo(presignedBillingLogo));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

export function* updateParticipantSaga() {
    yield takeLatest(UPDATE_PARTICIPANT, updateParticipant);
}

export function* getPresignedBillingLogoSaga() {
    yield takeLatest(GET_PRESIGNED_BILLING_LOGO, getPresignedBillingLogo);
}

const participantSagas = [updateParticipantSaga, getPresignedBillingLogoSaga];

export default participantSagas;
