import moment from 'moment';

export function addHours(from, hours) {
    const date = moment(from);
    const tomorrow = date.clone();
    tomorrow.add(1, 'd');
    return !date.isDST() && tomorrow.isDST() && date.hour() === 0
        ? date.add(hours - 1, 'h')
        : date.isDST() && !tomorrow.isDST() && date.hour() === 0
        ? date.add(hours + 1, 'h')
        : date.add(hours, 'h');
}

export function dateRangeOverlaps({ from, to }, activationRange) {
    if (!(from && to)) {
        return true;
    }
    return (
        !(
            activationRange &&
            activationRange[0] &&
            activationRange[0].value &&
            moment(to).isBefore(moment(activationRange[0].value))
        ) ||
        (activationRange &&
            activationRange[1] &&
            activationRange[1].value &&
            moment(activationRange[1].value).isBefore(moment(from)))
    );
}
