const AccountStatus = {
    ACTIVE: 'participant actif',
    INITIALIZED: 'compte créé inactif',
    UNINITIALIZED: 'pas de compte',
    ERROR: 'erreur',
};
function accountStatus(participant) {
    const unInitialized = !participant.user;
    const initializedButInactive =
        participant.user && !participant.user.initialized;
    const active = participant.user && participant.user.initialized;

    function tagMsg() {
        if (unInitialized) {
            return AccountStatus.UNINITIALIZED;
        } else if (initializedButInactive) {
            return AccountStatus.INITIALIZED;
        } else if (active) return AccountStatus.ACTIVE;
        else return AccountStatus.ERROR;
    }
    return tagMsg();
}

export { AccountStatus, accountStatus };
