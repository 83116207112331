import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
    getConfigs as getConfigsAction,
    setError,
    setExcessBills,
    setExcessBuyers,
    setExcessConfigs,
    setParticipantsBills,
    setParticipantsConfigs,
    getBills as getBillsAction,
    getOperationParticipants as getOperationParticipantsAction,
    setDownloadLoading,
} from '../../redux/actions';
import {
    CREATE_CONFIG,
    DOWNLOAD_BILL_DOCUMENTS,
    DOWNLOAD_BILLING_STATUS,
    EDIT_CONFIG,
    EDIT_EXCESS_BILL,
    GET_BILLS,
    GET_CONFIGS,
    GET_EXCESS_BUYERS,
    REMOVE_CONFIG,
    UPDATE_BILLS,
    UPLOAD_EXCESS_CONTRACT,
    UPLOAD_EXCESS_TEMPLATE,
} from '../../redux/reducers/constants';
import { configTypes, salesAgreementMaxSize } from '../../../../app-config';
import {
    selectSelectedOperation,
    selectSelectedOperationId,
} from '../../redux/selectors/operationSelectors';
import { selectUser } from '../../redux/selectors/authenticationSelectors';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

function* getConfigs(action) {
    try {
        const { configType } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        const configs = yield call(
            billingGateway.getConfigs,
            operation.id,
            configType
        );
        yield put(
            configType === configTypes.EXCESS
                ? setExcessConfigs(configs)
                : setParticipantsConfigs(configs)
        );
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* createConfig(action) {
    try {
        const { config, configType } = action;
        const {
            generalContract,
            particularContracts,
            excessContract,
            templateFile,
        } = config;
        if (configType === configTypes.EXCESS) {
            if (
                excessContract &&
                !['pdf', 'doc'].includes(
                    excessContract.name.split('.').pop().toLowerCase()
                )
            ) {
                throw new Error(
                    'Format non supporté (formats supportés: .pdf, .doc)'
                );
            }
            if (
                excessContract &&
                excessContract.size > salesAgreementMaxSize * 1000
            ) {
                throw new Error(
                    `La taille du fichier ne doit pas dépasser ${salesAgreementMaxSize}Ko`
                );
            }
            if (
                templateFile &&
                !['doc', 'docx', 'xls', 'xlsx', 'ods', 'odt'].includes(
                    templateFile.name.split('.').pop().toLowerCase()
                )
            ) {
                throw new Error(
                    'Format non supporté (formats supportés: .doc, .docx, .xls, .xlsx, .ods, .odt)'
                );
            }
        } else {
            if (
                generalContract &&
                !['pdf', 'doc'].includes(
                    generalContract.name.split('.').pop().toLowerCase()
                )
            ) {
                throw new Error(
                    'Format non supporté (formats supportés: .pdf, .doc)'
                );
            }
            if (
                generalContract &&
                generalContract.size > salesAgreementMaxSize * 1000
            ) {
                throw new Error(
                    `La taille du fichier ne doit pas dépasser ${salesAgreementMaxSize}Ko`
                );
            }
            let files = [];
            particularContracts.forEach((partContract) => {
                files = [...files, partContract.file];
            });
            if (
                files.some(
                    (file) =>
                        !['pdf', 'doc'].includes(
                            file.name.split('.').pop().toLowerCase()
                        )
                )
            ) {
                throw new Error("format d'un contrat particulier inaccepté");
            }
            if (
                files.some((file) => file.size > salesAgreementMaxSize * 1000)
            ) {
                throw new Error(
                    `La taille du fichier ne doit pas dépasser ${salesAgreementMaxSize}Ko`
                );
            }
        }
        const operation = yield select(selectSelectedOperation);
        const billingGateway = yield getContext('billingGateway');
        yield call(
            billingGateway.createConfig,
            config,
            operation.id,
            configType
        );
        yield put(getConfigsAction(configType));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* deleteConfig(action) {
    try {
        const { configId, configType } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(billingGateway.deleteConfig, operation.id, configId);
        yield put(getConfigsAction(configType));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* editConfig(action) {
    try {
        const { config, configType, isNewConfig } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.editConfig,
            config,
            operation.id,
            configType,
            isNewConfig
        );
        yield put(getConfigsAction(configType));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* getExcessBuyers() {
    try {
        const billingGateway = yield getContext('billingGateway');
        const operationId = yield select(selectSelectedOperationId);
        const excessBuyers = yield call(
            billingGateway.getExcessBuyers,
            operationId
        );
        yield put(setExcessBuyers(excessBuyers));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* downloadBillDocuments(action) {
    try {
        const { bills } = action;
        const billingGateway = yield getContext('billingGateway');

        if (bills.length === 1) {
            const url = yield call(
                billingGateway.getBillDocumentPresignedUrl,
                bills[0]
            );
            window.location.href = url.data.url;
        } else {
            yield put(setDownloadLoading(true));
            const urlsResponses = yield call(
                billingGateway.getMultipleBillDocumentsPresignedUrlObjects,
                bills
            );
            const urlObjects = urlsResponses.map((item) => {
                return {
                    title: item.title,
                    url: item.content.data.url,
                };
            });
            const period = `f-${bills[0].billingPeriodEnd}`;
            const producer = bills[0].producer;
            const zipFilename = `${period}-${producer.billingDetail.name}.zip`;
            yield downloadZipFromUrlObjects(urlObjects, zipFilename);
        }
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
    yield put(setDownloadLoading(false));
}

const downloadZipFromUrlObjects = async (urlObjects, zipFilename) => {
    const zip = new JSZip();
    await Promise.all(
        urlObjects.map(async (urlObject) => {
            const data = await JSZipUtils.getBinaryContent(urlObject.url);
            zip.file(urlObject.title, data, { binary: true });
        })
    );
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, zipFilename);
};

function* getBills(action) {
    try {
        const { configType } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        const user = yield select(selectUser);
        let bills;
        if (user.role === 'participant') {
            bills = yield call(
                billingGateway.getParticipantBills,
                operation.activeProducerId,
                configType
            );
        } else {
            bills = yield call(
                billingGateway.getBills,
                operation.id,
                configType
            );
        }
        yield put(
            configType === configTypes.EXCESS
                ? setExcessBills(bills)
                : setParticipantsBills(bills)
        );
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* updateBills(action) {
    try {
        const { bills } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(billingGateway.updateBills, operation.id, bills);
        yield put(
            getBillsAction(
                bills[0].consumer ? configTypes.PARTICIPANT : configTypes.EXCESS
            )
        );
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* editExcessBill(action) {
    try {
        const { startDate, endDate, producerId } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(billingGateway.editExcessBill, operation.id, {
            startDate,
            endDate,
            producerId,
        });
        yield put(getBillsAction(configTypes.EXCESS));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* uploadExcessContract(action) {
    try {
        const { contract, producerId } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(billingGateway.uploadExcessContract, operation.id, {
            producerId,
            contract,
        });
        yield put(getOperationParticipantsAction(operation));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* uploadExcessTemplate(action) {
    try {
        const { template, producerId } = action;
        const billingGateway = yield getContext('billingGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(billingGateway.uploadExcessTemplate, operation.id, {
            producerId,
            template,
        });
        yield put(getOperationParticipantsAction(operation));
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* downloadBillingStatus(action) {
    try {
        const { producer, billingPeriodEnd } = action;
        const billingGateway = yield getContext('billingGateway');
        yield call(
            billingGateway.downloadBillingStatus,
            producer.id,
            billingPeriodEnd
        );
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

export function* getConfigsSaga() {
    yield takeLatest(GET_CONFIGS, getConfigs);
}

export function* createConfigSaga() {
    yield takeLatest(CREATE_CONFIG, createConfig);
}

export function* deleteConfigSaga() {
    yield takeLatest(REMOVE_CONFIG, deleteConfig);
}

export function* editConfigSaga() {
    yield takeLatest(EDIT_CONFIG, editConfig);
}

export function* getExcessBuyersSaga() {
    yield takeLatest(GET_EXCESS_BUYERS, getExcessBuyers);
}

export function* downloadBillDocumentsSaga() {
    yield takeLatest(DOWNLOAD_BILL_DOCUMENTS, downloadBillDocuments);
}

export function* getBillsSaga() {
    yield takeLatest(GET_BILLS, getBills);
}

export function* updateBillsSaga() {
    yield takeLatest(UPDATE_BILLS, updateBills);
}

export function* editExcessBillSaga() {
    yield takeLatest(EDIT_EXCESS_BILL, editExcessBill);
}

export function* uploadExcessContractSaga() {
    yield takeLatest(UPLOAD_EXCESS_CONTRACT, uploadExcessContract);
}

export function* uploadExcessTemplateSaga() {
    yield takeLatest(UPLOAD_EXCESS_TEMPLATE, uploadExcessTemplate);
}

export function* downloadBillingStatusSaga() {
    yield takeLatest(DOWNLOAD_BILLING_STATUS, downloadBillingStatus);
}

const billingSagas = [
    getConfigsSaga,
    createConfigSaga,
    deleteConfigSaga,
    editConfigSaga,
    getExcessBuyersSaga,
    downloadBillDocumentsSaga,
    getBillsSaga,
    updateBillsSaga,
    editExcessBillSaga,
    uploadExcessContractSaga,
    uploadExcessTemplateSaga,
    downloadBillingStatusSaga,
];

export default billingSagas;
