import {
    all,
    call,
    getContext,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import {
    initRange,
    initSelectedDates,
    setCDC,
    setCDCLoading,
    setDistribution,
    setDistributionLoading,
    setError,
    setMetrics,
    setMetricsLoading,
    setRange,
    setRangeLoading,
    setSelectedDates,
} from '../../redux/actions';
import {
    GET_CDC_DATA,
    GET_CONSUMER_CDC_DATA,
    GET_PARTICIPANT_METRICS_DATA,
    GET_METRICS_DATA,
    GET_RANGE,
    GET_PRODUCER_CDC_DATA,
    GET_DISTRIBUTION_DATA,
} from '../../redux/reducers/constants';
import { operationFunctionalities } from '../../../../app-config';
import {
    selectIsOperationACI,
    selectSelectedOperation,
} from '../../redux/selectors/operationSelectors';
import { selectSelectedDates } from '../../redux/selectors/measurementSelectors/sharedSelectors';
import moment from 'moment';
import { defaultPeriod } from '../../../adapters/primary/ui/enoapp/app-config';

function* getDateRange(action) {
    try {
        yield put(setRangeLoading(true));
        const measurementGateway = yield getContext('measurementGateway');
        const [selectedOperation, selectedDates] = yield all([
            select(selectSelectedOperation),
            select(selectSelectedDates),
        ]);
        const participantIds = action.participantId
            ? [action.participantId]
            : null;
        let { uploadedData, sgeTiersData } = yield call(
            measurementGateway.getDateRanges,
            selectedOperation && selectedOperation.id,
            participantIds
        );
        const uploadedDataRange = consolidateRanges(uploadedData);
        const sgeTiersRange = consolidateRanges(sgeTiersData);
        const range = {
            from:
                uploadedDataRange.from && sgeTiersRange.from
                    ? moment.min(uploadedDataRange.from, sgeTiersRange.from)
                    : uploadedDataRange.from || sgeTiersRange.from,
            to:
                uploadedDataRange.to && sgeTiersRange.to
                    ? moment.max(uploadedDataRange.to, sgeTiersRange.to)
                    : uploadedDataRange.to || sgeTiersRange.to,
        };
        yield put(
            setRange({
                uploadedData: uploadedDataRange,
                sgeTiersData: sgeTiersRange,
            })
        );
        if (range.from && range.to) {
            const newRangeTo = range.to.clone();
            const from = moment
                .max(
                    newRangeTo
                        .subtract(defaultPeriod.value, defaultPeriod.unit)
                        .add(1, 'days'),
                    range.from
                )
                .hours(0)
                .minutes(0)
                .seconds(0);
            let newSelectedDates = { ...selectedDates };
            if (
                !(
                    selectedDates.from &&
                    range.from.isSameOrBefore(selectedDates.from, 'day') &&
                    selectedDates.to &&
                    range.to.isSameOrAfter(selectedDates.to, 'day')
                )
            ) {
                newSelectedDates = {
                    from,
                    to: range.to,
                };
            }
            yield put(setSelectedDates(newSelectedDates));
        } else {
            yield put(initRange());
            yield put(initSelectedDates());
        }
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
    yield put(setRangeLoading(false));
}

function* getGlobalCDC() {
    try {
        yield put(setCDCLoading(true));
        const measurementGateway = yield getContext('measurementGateway');
        const [{ id: operationId, functionalities }, { from, to }] = yield all([
            select(selectSelectedOperation),
            select(selectSelectedDates),
        ]);
        const isOperationACI =
            functionalities &&
            functionalities.indexOf(operationFunctionalities.ACI) > -1;
        const dataCalls = [];
        dataCalls.push(
            call(measurementGateway.getConsumptionData, operationId, from, to)
        );
        dataCalls.push(
            call(measurementGateway.getProductionData, operationId, from, to)
        );
        dataCalls.push(
            call(
                measurementGateway.getAutoConsumptionData,
                operationId,
                from,
                to
            )
        );
        dataCalls.push(
            call(measurementGateway.getSurplusData, operationId, from, to)
        );
        if (isOperationACI) {
            dataCalls.push(
                call(
                    measurementGateway.getExternalConsumption,
                    operationId,
                    from,
                    to
                )
            );
            dataCalls.push(
                call(
                    measurementGateway.getExternalProduction,
                    operationId,
                    from,
                    to
                )
            );
        }
        const [
            consumption,
            production,
            autoConsumption,
            surplus,
            extConsumption,
            extProduction,
        ] = yield all(dataCalls);
        yield put(
            setCDC({
                consumption,
                production,
                autoConsumption,
                surplus,
                extConsumption,
                extProduction,
            })
        );
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
    yield put(setCDCLoading(false));
}

function* getGlobalMetrics() {
    try {
        yield put(setMetricsLoading(true));
        const measurementGateway = yield getContext('measurementGateway');
        const [{ id: operationId }, { from, to }] = yield all([
            select(selectSelectedOperation),
            select(selectSelectedDates),
        ]);

        const metrics = yield call(
            measurementGateway.getMetrics,
            operationId,
            from,
            to
        );
        yield put(setMetrics(metrics));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
    yield put(setMetricsLoading(false));
}

function* getConsumerCDC(action) {
    try {
        yield put(setCDCLoading(true));
        const { consumerId } = action;
        const measurementGateway = yield getContext('measurementGateway');
        const { from, to } = yield select(selectSelectedDates);
        const dataCalls = [];
        dataCalls.push(
            call(
                measurementGateway.getConsumerConsumptionData,
                consumerId,
                from,
                to
            )
        );
        dataCalls.push(
            call(
                measurementGateway.getConsumerAutoConsumptionData,
                consumerId,
                from,
                to
            )
        );
        const [consumption, autoConsumption] = yield all(dataCalls);
        yield put(
            setCDC({
                consumption,
                autoConsumption,
            })
        );
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
    yield put(setCDCLoading(false));
}

function* getParticipantMetrics(action) {
    try {
        yield put(setMetricsLoading(true));
        const { participantId, isConsumer } = action;
        const measurementGateway = yield getContext('measurementGateway');
        const { from, to } = yield select(selectSelectedDates);
        const metrics = yield call(
            isConsumer
                ? measurementGateway.getConsumerMetrics
                : measurementGateway.getProducerMetrics,
            participantId,
            from,
            to
        );
        yield put(setMetrics(metrics));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
    yield put(setMetricsLoading(false));
}

function* getProducerCDC(action) {
    try {
        yield put(setCDCLoading(true));
        const { producerId } = action;
        const measurementGateway = yield getContext('measurementGateway');
        const [isOperationACI, { from, to }] = yield all([
            select(selectIsOperationACI),
            select(selectSelectedDates),
        ]);
        const dataCalls = [];
        dataCalls.push(
            call(
                measurementGateway.getProducerProductionData,
                producerId,
                from,
                to
            )
        );
        dataCalls.push(
            call(
                measurementGateway.getProducerAutoConsumptionData,
                producerId,
                from,
                to
            )
        );
        if (isOperationACI) {
            dataCalls.push(
                call(
                    measurementGateway.getProducerExternalConsumptionData,
                    producerId,
                    from,
                    to
                )
            );
            dataCalls.push(
                call(
                    measurementGateway.getProducerExternalProductionData,
                    producerId,
                    from,
                    to
                )
            );
        }
        const [production, autoConsumption, extConsumption, extProduction] =
            yield all(dataCalls);
        yield put(
            setCDC({
                production,
                autoConsumption,
                extConsumption,
                extProduction,
            })
        );
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
    yield put(setCDCLoading(false));
}

function* getDistribution() {
    try {
        yield put(setDistributionLoading(true));
        const measurementGateway = yield getContext('measurementGateway');
        const [{ id: operationId }, { from, to }] = yield all([
            select(selectSelectedOperation),
            select(selectSelectedDates),
        ]);

        const distribution = yield call(
            measurementGateway.getDistribution,
            operationId,
            from,
            to
        );

        yield put(setDistribution(distribution));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
    yield put(setDistributionLoading(false));
}

export function* getDateRangeSaga() {
    yield takeLatest(GET_RANGE, getDateRange);
}

export function* getCDCSaga() {
    yield takeLatest(GET_CDC_DATA, getGlobalCDC);
}

export function* getMetricsSaga() {
    yield takeLatest(GET_METRICS_DATA, getGlobalMetrics);
}

export function* getConsumerCDCSaga() {
    yield takeLatest(GET_CONSUMER_CDC_DATA, getConsumerCDC);
}

export function* getProducerCDCSaga() {
    yield takeLatest(GET_PRODUCER_CDC_DATA, getProducerCDC);
}

export function* getConsumerMetricsSaga() {
    yield takeLatest(GET_PARTICIPANT_METRICS_DATA, getParticipantMetrics);
}

export function* getDistributionSaga() {
    yield takeLatest(GET_DISTRIBUTION_DATA, getDistribution);
}

const consolidateRanges = (dates) => {
    let consolidatedRange =
        dates.length > 0
            ? dates.reduce((acc, curr) => ({
                  from: acc.from < curr.from ? acc.from : curr.from,
                  to: acc.to > curr.to ? acc.to : curr.to,
              }))
            : null;
    const range = {
        from: consolidatedRange && moment(consolidatedRange.from),
        to: consolidatedRange && moment(consolidatedRange.to),
    };

    return range;
};

const measurementSagas = [
    getDateRangeSaga,
    getCDCSaga,
    getMetricsSaga,
    getConsumerCDCSaga,
    getProducerCDCSaga,
    getConsumerMetricsSaga,
    getDistributionSaga,
];

export default measurementSagas;
