import {
    all,
    call,
    getContext,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import {
    getOperations as getOperationsAction,
    setError,
    setOperations,
    setSelectedOperation,
} from '../../redux/actions';
import {
    GET_OPERATION_PARTICIPANTS,
    GET_OPERATIONS,
    UPDATE_OPERATION,
} from '../../redux/reducers/constants';
import { selectUser } from '../../redux/selectors/authenticationSelectors';
import { selectRoutes } from '../../redux/selectors/routeSelector';
import { roles, routesConstants } from '../../../../app-config';
import { createUrl } from '../../../adapters/primary/libs/createUrl';

function* getOperations(action) {
    try {
        let operations;
        const operationGateway = yield getContext('operationGateway');
        // action dispatched from backoffice
        if (action && action.role === 'admin') {
            operations = yield call(operationGateway.getAllOperations);
        } else {
            const { role } = yield select(selectUser);
            switch (role) {
                case 'participant':
                    const participants = yield call(
                        operationGateway.getParticipantsByJwt
                    );
                    const operationsIds = [
                        ...new Set(
                            participants.map((part) => part.operationId)
                        ),
                    ];
                    operations = yield call(
                        operationGateway.getOperations,
                        operationsIds
                    );
                    operations.forEach((operation) => {
                        operation.producers = participants.filter(
                            (part) => part.operationId === operation.id
                        );
                    });
                    break;
                case 'observer':
                    operations = yield call(
                        operationGateway.getObserverOperations,
                        false
                    );
                    yield put(setOperations(operations));
                    operations = yield call(
                        operationGateway.getObserverOperations,
                        true
                    );
                    break;
                case 'organizer':
                    const organizer = yield call(
                        operationGateway.getOrganizerByJwt
                    );
                    operations = yield call(
                        operationGateway.getOrganizerOperations,
                        organizer.id,
                        false
                    );
                    yield put(setOperations(operations));
                    operations = yield call(
                        operationGateway.getOrganizerOperations,
                        organizer.id,
                        true
                    );
                    break;
                default:
            }
            const getPresignedLogosCalls = operations.map((operation) =>
                call(operationGateway.getPresignedLogo, operation)
            );
            yield all(getPresignedLogosCalls);
        }
        yield put(setOperations(operations));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

function* getOperationParticipantsAndObservers(action) {
    try {
        const operationGateway = yield getContext('operationGateway');
        const measurementGateway = yield getContext('measurementGateway');
        const { operation, dateFrom, dateTo } = action;
        if (operation) {
            const { role } = (yield select(selectUser)) || {};
            const participantsAndObserversCalls = [
                call(
                    operationGateway.getOperationParticipants,
                    operation.id,
                    dateFrom,
                    dateTo
                ),
            ];
            if (role === roles.ORGANIZER) {
                participantsAndObserversCalls.push(
                    call(operationGateway.getOperationObservers, operation.id)
                );
            }
            const [participants, observers] = yield all(
                participantsAndObserversCalls
            );
            operation.observers = observers || [];
            const { history } = (yield select(selectRoutes)) || {};
            if (role !== roles.PARTICIPANT) {
                operation.producers = [];
                operation.consumers = [];
                participants.forEach((participant) =>
                    (participant.energyType === 'consumer'
                        ? operation.consumers
                        : operation.producers
                    ).push(participant)
                );
            } else {
                let { uploadedData } = yield call(
                    measurementGateway.getDateRanges,
                    operation.id,
                    operation.producers.map((prod) => prod.id)
                );
                operation.activeProducerId =
                    uploadedData.length > 0
                        ? uploadedData.reduce((acc, curr) =>
                              acc.to > curr.to ? acc : curr
                          ).participantId
                        : operation.producers[0].id;
                history.push(
                    createUrl(
                        routesConstants.ENOAPP,
                        `operations/${operation.id}/dashboard/prod/${operation.activeProducerId}`
                    )
                );
                operation.consumers = participants.filter(
                    (participant) => participant.energyType === 'consumer'
                );
            }

            yield put(setSelectedOperation(operation));
        }
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

function* updateOperation(action) {
    try {
        const { operation, formData } = action;
        const operationGateway = yield getContext('operationGateway');
        const updateCalls = [];
        updateCalls.push(call(operationGateway.updateOperation, operation));
        if (formData) {
            updateCalls.push(
                call(operationGateway.uploadLogo, operation, formData)
            );
        }
        yield all(updateCalls);
        yield put(getOperationsAction());
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

export function* getOperationsSaga() {
    yield takeLatest(GET_OPERATIONS, getOperations);
}

export function* getOperationParticipantsSaga() {
    yield takeLatest(
        GET_OPERATION_PARTICIPANTS,
        getOperationParticipantsAndObservers
    );
}

export function* updateOperationSaga() {
    yield takeLatest(UPDATE_OPERATION, updateOperation);
}

const operationSagas = [
    getOperationsSaga,
    getOperationParticipantsSaga,
    updateOperationSaga,
];

export default operationSagas;
