import { createSelector } from 'reselect';

const selectAuthentication = (state) => state.authentication;

const selectUser = createSelector(
    selectAuthentication,
    (authentication) => authentication.user
);

const selectCgu = createSelector(
    selectAuthentication,
    (authentication) => authentication.cgu
);

const selectEnedisConsentPopup = createSelector(
    selectAuthentication,
    (authentication) => authentication.enedisConsentPopup
);

const selectIsAuthenticating = createSelector(
    selectAuthentication,
    (authentication) => authentication.isAuthenticating
);

export {
    selectUser,
    selectCgu,
    selectIsAuthenticating,
    selectEnedisConsentPopup,
};
