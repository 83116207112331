export const InfoStatus = {
    ERROR: 'erreur de données',
    HAS_BEEN_DELETED: 'sortie du périmètre',
    UP_TO_DATE: 'intégré au périmètre',
    DELETING: 'demande de sortie en cours',
    CREATING: 'en cours de création',
    PROCESSING: 'en cours de traitement',
};

export default function infoStatus(participant) {
    const statusError = participant.enedisInfoStatus === 'NO_TRAIL_ERR';
    const statusUpToDate = participant.enedisInfoStatus === 'EN_VIGUEUR';
    const statusProcessing = !statusError && !statusUpToDate;
    const participantOut = dateHasExpired(
        participant.enedisDetail.activationRangeEnd
    );
    const participantIn = dateHasExpired(
        participant.enedisDetail.activationRangeStart
    );

    function dateHasExpired(dateEnd) {
        if (!dateEnd) return false;
        else {
            return new Date(dateEnd) < Date.now();
        }
    }

    const actionError = participant.enedisActionType === 'NO_TRAIL_ERR';
    const actionDelete = participant.enedisActionType === 'SUPPRESSION';
    const actionCreate = participant.enedisActionType === 'CREATION';
    const actionProcess = !actionDelete && !actionCreate;

    function tagMsg() {
        if (statusError || actionError) return InfoStatus.ERROR;
        if (participantOut) return InfoStatus.HAS_BEEN_DELETED;
        else if (statusUpToDate) {
            if (actionDelete) return InfoStatus.HAS_BEEN_DELETED;
            if (actionProcess) return InfoStatus.UP_TO_DATE;
        } else if (statusProcessing) {
            if (actionDelete) return InfoStatus.DELETING;
            if (actionCreate && participantIn) return InfoStatus.UP_TO_DATE;
            if (actionCreate && !participantIn) return InfoStatus.CREATING;
            if (actionProcess) return InfoStatus.PROCESSING;
        }
        return InfoStatus.ERROR;
    }

    return tagMsg();
}
