const colors = {
    PRODUCTION: '#6abc6a',
    SELF_CONSUMPTION_PRODUCER: '#FFF03F',
    SELF_CONSUMPTION_COLLECTIF: '#f3b617',
    COMPLEMENTARY_PRODUCER: '#004E97',
    COMPLEMENTARY_COLLECTIF: '#84a6cf',
    EXCESS: '#c4c4c4',
    SOFT_BG: '#F9F9F9',
    EXPENSE: '#f3b617',
    INCOME: '#84a6cf',
};

const customizationColors = [
    '#4D4D4D',
    '#999999',
    '#FFFFFF',
    '#F44E3B',
    '#FE9200',
    '#FCDC00',
    '#DBDF00',
    '#A4DD00',
    '#68CCCA',
    '#73D8FF',
    '#AEA1FF',
    '#FDA1FF',
    '#333333',
    '#808080',
    '#cccccc',
    '#D33115',
    '#E27300',
    '#FCC400',
    '#B0BC00',
    '#68BC00',
    '#16A5A5',
    '#009CE0',
    '#7B64FF',
    '#FA28FF',
    '#000000',
    '#666666',
    '#B3B3B3',
    '#9F0500',
    '#C45100',
    '#FB9E00',
    '#808900',
    '#194D33',
    '#0C797D',
    '#0062B1',
    '#653294',
    '#AB149E',
];

const participantsFilterTypes = {
    CONSUMERS: 'consumers',
    PRODUCERS: 'producers',
    OBSERVERS: 'observers',
};

const appsRoutes = {
    enoApp: '/pmo',
    admin: '/admin',
};

const routesConstants = {
    ENOAPP: 'enoApp',
    ADMIN: 'admin',
};

const roles = {
    PARTICIPANT: 'participant',
    ORGANIZER: 'organizer',
    OBSERVER: 'observer',
    ADMIN: 'admin',
};

const participantTypes = {
    CONSUMER: 'consumer',
    PRODUCER: 'producer',
};

const autoconsommationDashboardTypes = {
    ACC: 'ACC',
    ACI: 'AC collective + individuelle',
    ACIWithDetails: 'AC collective + individuelle en details',
};

const maxLevel = 5;

const rules = {
    // pages
    HOME_VISIT: {
        legacyName: 'home-page:visit',
        resource: 'enoapp:front:home-page',
        action: 'visit',
    },
    DASHBOARD_VISIT: {
        legacyName: 'dashboard-page:visit',
        resource: 'enoapp:front:dashboard-page',
        action: 'visit',
    },
    GLOBAL_VISIT: {
        legacyName: 'global-page:visit',
        resource: 'enoapp:front:global-page',
        action: 'visit',
    },
    PRODUCER_VISIT: {
        legacyName: 'producer-page:visit',
        resource: 'enoapp:front:producer-page',
        action: 'visit',
    },
    PRODUCTION_VISIT: {
        legacyName: 'production-page:visit',
        resource: 'enoapp:front:production-page',
        action: 'visit',
    },
    DISTRIBUTION_VISIT: {
        legacyName: 'distribution-page:visit',
        resource: 'enoapp:front:distribution-page',
        action: 'visit',
    },
    CONSUMER_VISIT: {
        legacyName: 'consumer-page:visit',
        resource: 'enoapp:front:consumer-page',
        action: 'visit',
    },
    BILLING_VISIT: {
        legacyName: 'billing-page:visit',
        resource: 'enoapp:front:billing-page',
        action: 'visit',
    },
    DOCUMENTS_VISIT: {
        legacyName: 'documents-page:visit',
        resource: 'enoapp:front:documents-page',
        action: 'visit',
    },
    OPERATION_VISIT: {
        legacyName: 'operation-page:visit',
        resource: 'enoapp:front:operation-page',
        action: 'visit',
    },
    PARTICIPANTS_VISIT: {
        legacyName: 'participant-page:visit',
        resource: 'enoapp:front:participant-page',
        action: 'visit',
    },
    ACCOUNT_BILLING_VISIT: {
        legacyName: 'account-billing-page:visit',
        resource: 'enoapp:front:account-billing-page',
        action: 'visit',
    },
    ALERT_MANAGEMENT_VISIT: {
        legacyName: 'alert-management-page:visit',
        resource: 'enoapp:front:alert-management-page',
        action: 'visit',
    },
    EXPENSE_VISIT: {
        legacyName: 'expense-page:visit',
        resource: 'enoapp:front:expense-page',
        action: 'visit',
    },
    INCOME_VISIT: {
        legacyName: 'income-page:visit',
        resource: 'enoapp:front:income-page',
        action: 'visit',
    },
    FINMONITORING_VISIT: {
        legacyName: 'finmonitoring-page:visit',
        resource: 'enoapp:front:finmonitoring-page',
        action: 'visit',
    },
    COMMUNICATION_VISIT: {
        legacyName: 'commmunication-page:visit',
        resource: 'enoapp:front:communication-page',
        action: 'visit',
    },
    // apps
    ENOAPP_VISIT: {
        legacyName: 'enoapp:visit',
        resource: 'enoapp:front',
        action: 'visit',
    },
    // components
    UPLOAD_VIEW: {
        legacyName: 'upload-component:view',
        resource: 'enoapp:front:upload-component',
        action: 'view',
    },
    PARTICIPANT_DETAILS_VIEW: {
        legacyName: 'participant-detail-component-view',
        resource: 'enoapp:front:participant-detail-component',
        action: 'view',
    },
    DOWNLOAD_DOCUMENTS_VIEW: {
        legacyName: 'download-documents-component-view',
        resource: 'enoapp:front:download-documents-component',
        action: 'view',
    },
    UPLOAD_DOCUMENTS_VIEW: {
        legacyName: 'upload-documents-component-view',
        resource: 'enoapp:front:upload-documents-component',
        action: 'view',
    },
    CREATE_FOLDER_VIEW: {
        legacyName: 'create-folder-component-view',
        resource: 'enoapp:front:create-folder-component',
        action: 'view',
    },
    SHARE_DOCUMENTS_VIEW: {
        legacyName: 'share-documents-component-view',
        resource: 'enoapp:front:share-documents-component',
        action: 'view',
    },
    EDIT_DOCUMENTS_VIEW: {
        legacyName: 'edit-documents-component-view',
        resource: 'enoapp:front:edit-documents-component',
        action: 'view',
    },
    DELETE_DOCUMENTS_VIEW: {
        legacyName: 'delete-documents-component-view',
        resource: 'enoapp:front:delete-documents-component',
        action: 'view',
    },
    FINANCE_AFFECTION: {
        legacyName: 'finance-affection:view',
        resource: 'enoapp:front:finance-affection-component',
        action: 'view',
    },
    PARTICIPANTS_CONFIGS_VIEW: {
        legacyName: 'participants-configs:view',
        resource: 'enoapp:front:participants-configs',
        action: 'view',
    },
    EXCESS_CONFIGS_VIEW: {
        legacyName: 'excess-configs:view',
        resource: 'enoapp:front:excess-configs',
        action: 'view',
    },
};

const operationFunctionalities = {
    ACI: 'ACI',
    GES_KPI: 'gesKpi',
};

const salesAgreementMaxSize = 16000;

const salesConditionsTypes = {
    GENERAL: 'general',
    PARTICULAR: 'particular',
};

const configTypes = {
    PARTICIPANT: 'participant',
    EXCESS: 'excess',
};
const billingPeriods = {
    1: 'mensuelle',
    2: 'bimestrielle',
    3: 'trimestrielle',
    4: 'quadrimestrielle',
    6: 'semestrielle',
    12: 'annuelle',
};

const paymentMethods = {
    cheque: 'paiement par chèque',
    transfer: 'paiement par virement',
    publicTreasuryPaymentOrder: 'Titre de paiement auprès du Trésor Public',
    debit: 'paiement par prélèvement SEPA',
    publicTreasuryDebit: 'paiement par prélèvement du Trésor Public',
};

const contractTypesENUM = {
    BASE: 'base',
    DOUBLE_PRICE: 'double_price',
    QUADRUPLE_PRICE: 'quadruple_price',
};

const consumerTypesENUM = {
    INDIVIDUAL: 'individual',
    PROFESSIONAL: 'professional',
};

export {
    appsRoutes,
    roles,
    routesConstants,
    rules,
    participantTypes,
    autoconsommationDashboardTypes,
    colors,
    maxLevel,
    operationFunctionalities,
    participantsFilterTypes,
    customizationColors,
    salesAgreementMaxSize,
    salesConditionsTypes,
    configTypes,
    billingPeriods,
    paymentMethods,
    contractTypesENUM,
    consumerTypesENUM,
};
