import { createSelector } from 'reselect';
import {
    operationFunctionalities,
    participantsFilterTypes,
} from '../../../../app-config';
import {
    getComparator,
    tableSort,
} from '../../../adapters/primary/libs/sortTable';
import { selectSelectedDates } from './measurementSelectors/sharedSelectors';
import { dateRangeOverlaps } from '../../../adapters/primary/libs/datesLib';
import { accountStatus } from '../../../adapters/primary/ui/enoapp/components/Participants/ViewListParticipants/accountStatus';
import infoStatus from '../../../adapters/primary/ui/enoapp/components/Participants/ViewListParticipants/infoStatus';

const operation = (state) => state.operation;

const selectSelectedOperation = createSelector(
    operation,
    (operation) => operation.selectedOperation
);

const selectSelectedOperationId = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.id
);
const selectSelectedOperationName = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.name
);
const selectSelectedOperationProducersNumber = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.producersNumber
);
const selectSelectedOperationIsAMultiProd = createSelector(
    selectSelectedOperation,
    selectSelectedOperationProducersNumber,
    (operation, producersNumber) => operation && producersNumber > 1
);

const selectOperations = createSelector(
    operation,
    (operation) => operation.operations
);

const selectFilter = createSelector(operation, (operation) => operation.filter);

const selectSorting = createSelector(selectFilter, (filter) => filter.sorting);

const selectSearch = createSelector(selectFilter, (filter) => filter.search);

const selectParticipants = createSelector(
    selectSelectedOperation,
    selectSorting,
    selectSearch,
    (selectedOperation, { order, orderBy, type }, search) => {
        let participants = selectedOperation
            ? selectedOperation.consumers
                ? selectedOperation.consumers.concat(
                      selectedOperation.producers
                  )
                : selectedOperation.producers
            : [];
        participants = participants.filter((participant) => {
            const accountStatusFilter = accountStatus(participant)
                .toLowerCase()
                .includes(search.toLowerCase());
            const infoStatusFilter = infoStatus(participant)
                .toLowerCase()
                .includes(search.toLowerCase());
            const nameFilter =
                participant.enedisDetail &&
                participant.enedisDetail.name &&
                participant.enedisDetail.name
                    .toLowerCase()
                    .includes(search.toLowerCase());
            const prmFilter =
                participant.enedisDetail &&
                participant.enedisDetail.prm &&
                participant.enedisDetail.prm
                    .toLowerCase()
                    .includes(search.toLowerCase());
            const padtFilter =
                participant.enedisDetail &&
                participant.enedisDetail.padt &&
                participant.enedisDetail.padt
                    .toLowerCase()
                    .includes(search.toLowerCase());
            const billingDetailFilter =
                participant.billingDetail &&
                participant.billingDetail.name &&
                participant.billingDetail.name
                    .toLowerCase()
                    .includes(search.toLowerCase());
            const enedisDetailFilter =
                participant.enedisDetail &&
                participant.enedisDetail.name &&
                participant.enedisDetail.name
                    .toLowerCase()
                    .includes(search.toLowerCase());
            return (
                search === '' ||
                billingDetailFilter ||
                enedisDetailFilter ||
                prmFilter ||
                padtFilter ||
                nameFilter ||
                accountStatusFilter ||
                infoStatusFilter
            );
        });
        if (order !== '' && orderBy !== '') {
            participants = tableSort(
                participants,
                getComparator(order, orderBy, type)
            );
        }
        return participants;
    }
);

const selectType = createSelector(
    selectFilter,
    (filter) => filter.participantType
);

const selectParticipantsAndObservers = createSelector(
    selectSelectedOperation,
    selectType,
    (operation, type) => {
        let participants = [];
        if (operation) {
            const data = {
                consumers: operation[participantsFilterTypes.CONSUMERS].map(
                    (consumer) => {
                        const name =
                            consumer?.name ||
                            consumer?.billingDetail?.name ||
                            consumer?.enedisDetail?.prm ||
                            '';
                        return { ...consumer, name, isConsumer: true };
                    }
                ),
                producers: operation[participantsFilterTypes.PRODUCERS].map(
                    (producer) => {
                        const name =
                            producer?.name ||
                            producer?.billingDetail?.name ||
                            producer?.enedisDetail?.prm ||
                            producer?.enedisDetail?.padt ||
                            '';
                        return { ...producer, name, isProducer: true };
                    }
                ),
                observers: operation[participantsFilterTypes.OBSERVERS].map(
                    (observer) => {
                        const name = observer?.name || '';
                        return { ...observer, name, isObserver: true };
                    }
                ),
            };
            participants = operation
                ? type !== 'all'
                    ? data[type]
                    : [...data.consumers, ...data.producers, ...data.observers]
                : [];
            participants.sort((a, b) => {
                return a.name.localeCompare(b.name) >= 0 ? 1 : -1;
            });
        }
        return participants;
    }
);

const selectFunctionalities = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.functionalities
);

const selectIsOperationACI = createSelector(
    selectFunctionalities,
    (functionalities) =>
        functionalities &&
        functionalities.indexOf(operationFunctionalities.ACI) > -1
);

const selectShowGesKpi = createSelector(
    selectFunctionalities,
    (functionalities) =>
        functionalities &&
        functionalities.indexOf(operationFunctionalities.GES_KPI) > -1
);

const selectTheme = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.theme
);

const selectDoesOperationUseEnphaseData = createSelector(
    selectSelectedOperation,
    (operation) => operation && operation.useEnphaseData
);

const selectActiveParticipants = createSelector(
    selectSelectedOperation,
    selectSelectedDates,
    (operation, selectedDates) => {
        return (
            operation && {
                consumers: operation.consumers.filter((consumer) =>
                    dateRangeOverlaps(
                        selectedDates,
                        consumer.enedisDetail &&
                            consumer.enedisDetail.activationRange
                    )
                ),
                producers: operation.producers.filter((producer) =>
                    dateRangeOverlaps(
                        selectedDates,
                        producer.enedisDetail &&
                            producer.enedisDetail.activationRange
                    )
                ),
            }
        );
    }
);

const selectActiveProsumers = createSelector(
    selectActiveParticipants,
    (participants) =>
        participants
            ? participants.producers
                  .filter((producer) => producer.isProsumer)
                  .map((producer) => producer.id)
            : []
);

export {
    selectSelectedOperation,
    selectSelectedOperationId,
    selectOperations,
    selectParticipants,
    selectSelectedOperationName,
    selectSorting,
    selectParticipantsAndObservers,
    selectType,
    selectIsOperationACI,
    selectShowGesKpi,
    selectTheme,
    selectDoesOperationUseEnphaseData,
    selectActiveParticipants,
    selectActiveProsumers,
    selectSelectedOperationIsAMultiProd,
};
