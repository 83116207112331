import { call, getContext, put, takeLatest, select } from 'redux-saga/effects';
import {
    setCgu,
    setEnedisConsentPopup,
    setError,
    setIsAuthenticating,
    setUser,
} from '../../redux/actions';
import {
    ACCEPT_CGU,
    CHANGE_ENEDIS_CONSENT,
    GET_USER,
    LOG_IN,
    LOG_OUT,
    CHANGE_PASSWORD,
} from '../../redux/reducers/constants';
import { roles } from '../../../../app-config';
import queryString from 'query-string';
import { selectAppMap } from '../../redux/selectors/navigationSelector';

const isEnedisConsentEndDateReached = (user) => {
    const enedisConsentUpdateDate = new Date(user.enedisConsentUpdateDate);
    const year = enedisConsentUpdateDate.getFullYear();
    const month = enedisConsentUpdateDate.getMonth();
    const day = enedisConsentUpdateDate.getDate();
    const endDate = new Date(year + 3, month, day);
    const now = new Date();

    return now.getTime() >= endDate.getTime();
};

function* getUser(action) {
    try {
        yield put(setIsAuthenticating(true));
        const { history } = action;
        const authenticationGateway = yield getContext('authenticationGateway');
        const user = yield call(authenticationGateway.getUser);
        window.localStorage.setItem('policy', JSON.stringify(user.policy));
        if (!user.hasAcceptedCgu && user.role !== roles.ADMIN) {
            yield put(setCgu({ open: true, showAcceptButton: true }));
            history.push('/login');
        } else {
            yield put(setUser(user));
        }
        if (
            user.role === 'participant' &&
            (user.enedisConsent === null || isEnedisConsentEndDateReached(user))
        ) {
            yield put(setEnedisConsentPopup(true));
        } else {
            yield put(setUser(user));
        }
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }

    yield put(setIsAuthenticating(false));
}

function* logIn(action) {
    try {
        const { history, email, password } = action;
        const authenticationGateway = yield getContext('authenticationGateway');
        const response = yield call(authenticationGateway.logIn, {
            email,
            password,
        });
        window.localStorage.setItem(
            'policy',
            JSON.stringify(response.data.policy)
        );
        const user = yield call(authenticationGateway.getUser);
        if (user.hasAcceptedCgu || user.role === roles.ADMIN) {
            yield put(setUser(user));
            const path = queryString.parse(history.location.search);
            history.push(path.redirect || '/');
        } else {
            yield put(setCgu({ open: true, showAcceptButton: true }));
        }
        if (
            user.role === 'participant' &&
            (user.enedisConsent === null || isEnedisConsentEndDateReached(user))
        ) {
            yield put(setEnedisConsentPopup(true));
        } else {
            yield put(setUser(user));
        }
    } catch (error) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: 'Utilisateur inconnu ou mot de passe incorrect',
                variant: 'warning',
            })
        );
        console.error(error);
    }
}

function* logOut(action) {
    try {
        const appMap = yield select(selectAppMap);
        window.location.assign(`${appMap.enoroot}/logout`);
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

function* acceptCGU(action) {
    try {
        const { cguId, history } = action;
        const authenticationGateway = yield getContext('authenticationGateway');
        yield call(authenticationGateway.acceptCgu, cguId);
        const user = yield call(authenticationGateway.getUser);
        yield put(setUser(user));
        yield put(setCgu({ open: false }));
        history.push('/');
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

function* changeEnedisConsent(action) {
    try {
        const { userId, consent } = action;
        const authenticationGateway = yield getContext('authenticationGateway');
        yield call(authenticationGateway.changeEnedisConsent, {
            userId,
            consent,
        });
        const user = yield call(authenticationGateway.getUser);
        yield put(setUser(user));
    } catch (error) {
        yield put(
            setError({ status: error.response && error.response.status })
        );
        console.error(error);
    }
}

function* changePassword(action) {
    try {
        const { oldPassword, newPassword, history } = action;
        const authenticationGateway = yield getContext('authenticationGateway');
        const firstTimeLogin = history.location.pathname === '/initAccount';
        yield call(authenticationGateway.changePassword, {
            oldPassword,
            newPassword,
        });
        if (firstTimeLogin) {
            yield call(authenticationGateway.initializeUser);
            const user = yield call(authenticationGateway.getUser);
            yield put(setUser(user));
        }
        const path = queryString.parse(history.location.search);
        history.push(path.redirect || '/');
    } catch (error) {
        const contactMsg =
            'Si le problème persiste, contactez le support (support@enogrid.com).';
        if (error.response && error.response.status === 401) {
            yield put(
                setError({
                    status: error.response && error.response.status,
                    message:
                        "L'ancien mot de passe indiqué est incorrect. " +
                        contactMsg,
                })
            );
        } else {
            yield put(
                setError({
                    status: error.response && error.response.status,
                    message:
                        `Une erreur s'est produite lors de l'enregistrement de votre mot de passe. Contactez le support avec l'erreur ${error.code}. ` +
                        contactMsg,
                })
            );
        }
        console.error(error);
    }
}

export function* getUserSaga() {
    yield takeLatest(GET_USER, getUser);
}

export function* logInSaga() {
    yield takeLatest(LOG_IN, logIn);
}

export function* logOutSaga() {
    yield takeLatest(LOG_OUT, logOut);
}

export function* acceptCGUSaga() {
    yield takeLatest(ACCEPT_CGU, acceptCGU);
}

export function* changeEnedisConsentSaga() {
    yield takeLatest(CHANGE_ENEDIS_CONSENT, changeEnedisConsent);
}

export function* changePasswordSaga() {
    yield takeLatest(CHANGE_PASSWORD, changePassword);
}

const authenticationSagas = [
    getUserSaga,
    logInSaga,
    logOutSaga,
    acceptCGUSaga,
    changeEnedisConsentSaga,
    changePasswordSaga,
];

export default authenticationSagas;
