import {
    SET_CONFIGS,
    SET_EXCESS_BUYERS,
    SET_EXCESS_CONFIGS,
} from './constants';

const initialState = {
    participants: [],
    excess: [],
    excessBuyers: [],
};

function billingConfigReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CONFIGS: {
            return { ...state, participants: [...action.configs] };
        }
        case SET_EXCESS_CONFIGS: {
            return { ...state, excess: [...action.configs] };
        }
        case SET_EXCESS_BUYERS: {
            return { ...state, excessBuyers: [...action.excessBuyers] };
        }
        default:
            return state;
    }
}

export default billingConfigReducer;
