import { createSelector } from 'reselect';
import { selectSelectedOperation } from './operationSelectors';
import { consumerTypesENUM } from '../../../../app-config';

const selectPresignedBillingLogo = (state) =>
    state.participant.presignedBillingLogo;

const selectConsumers = createSelector(selectSelectedOperation, (operation) =>
    operation ? operation.consumers : []
);

const makeSelectConsumerEntityType = (participantId) =>
    createSelector(selectConsumers, (consumers) => {
        const consumer = consumers.find(({ id }) => id === participantId);
        const entityType = consumer.enedisDetail.entityType;
        if (entityType?.startsWith('Professionnel')) {
            return consumerTypesENUM.PROFESSIONAL;
        }
        if (entityType?.startsWith('Particulier')) {
            return consumerTypesENUM.INDIVIDUAL;
        }
        return null;
    });

export {
    selectPresignedBillingLogo,
    selectConsumers,
    makeSelectConsumerEntityType,
};
