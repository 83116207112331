import client from './libs/client';

const authProvider = {
    login: (params) => {
        const { username, password } = params;
        return client
            .post('/session/login', {
                email: username,
                password: password,
            })
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject();
                }
                return response.data;
            })
            .then((response) => {
                if (response.role !== 'admin') {
                    return Promise.reject();
                }
                if (!response.success) {
                    return Promise.reject();
                }
                return Promise.resolve();
            });
    },
    logout: (params) => {
        client.get('/session/logout');
        return Promise.resolve();
    },
    checkAuth: (params) => {
        return client.get('/session').then((response) => {
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject();
            }
            return Promise.resolve();
        });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            client.get('/session/logout');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: (params) => {
        return client.get('/session').then((response) => {
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject();
            }
            return Promise.resolve();
        });
    },
};

export default authProvider;
