import client from '../../libs/client';

export default class AccountGateway {
    error;

    setError = (error) => {
        this.error = error;
    };

    getAccountInfo = async (userId) => {
        const accountInfo = await client.get(`/users/${userId}/account-info`);
        return accountInfo.data;
    };
}
