import { createMuiTheme } from '@material-ui/core';

const prodPalette = (mainPrimary, mainSecondary) => ({
    primary: {
        main: mainPrimary || '#001e62',
        mainFaded: '#36416d',
        contrastText: '#fff',
        light: '#84a6cf',
        dark: mainPrimary || '#071b48',
    },
    secondary: {
        main: mainSecondary || '#F3B617',
        dark: '#F2A900',
        contrastText: '#fff',
        light: '#F4CE36',
    },
});

const stagingPalette = () => ({
    primary: {
        main: '#9a701c',
        mainFaded: '#d4a350',
        contrastText: '#fff',
        light: '#ffda84',
        dark: '#9a501e',
    },
    secondary: {
        main: '#6ABC6A',
        dark: '#008476',
        contrastText: '#fff',
        light: '#ACD45B',
    },
});

const getPalette = (mainPrimary, mainSecondary) => {
    return process.env.REACT_APP_ENV === 'production'
        ? prodPalette(mainPrimary, mainSecondary)
        : stagingPalette();
};

const theme = (mainPrimary, mainSecondary) =>
    createMuiTheme({
        palette: {
            ...getPalette(mainPrimary, mainSecondary),
            blackToWhite: {
                dark: '#000000',
                black: '#2F2F2F',
                blackGrey: '#4D4D4D',
                medium: '#727272',
                whiteGrey: '#a5a5a5',
                whiteWhiteGrey: '#d0d0d0 !important',
                almostWhite: '#F5F5F5',
                white: '#ffffff',
            },
        },
        typography: {
            fontSize: 15,
            fontFamily: ['"Nunito Sans"', 'sans-serif'].join(','),
        },
        overrides: {
            MuiToolbar: {
                gutters: {
                    paddingLeft: '16px !important',
                },
            },
            MuiButton: {
                root: {
                    color: mainSecondary || '#F3B617',
                    borderColor: `${mainSecondary || '#F3B617'} !important`,
                },
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: 15,
                    backgroundColor: 'white',
                    color: 'black',
                    border: `solid 1px black`,
                },
            },
            MuiList: {
                padding: {
                    paddingTop: 0,
                },
            },
            MuiListItem: {
                root: {
                    color: '#707070',
                },
                button: {
                    '&:hover': {
                        color: 'black',
                    },
                },
            },

            MuiTypography: {
                root: {
                    alignSelf: 'center',
                },
            },
            MuiTableCell: {
                head: {
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingLeft: 5,
                    paddingRight: 5,
                    backgroundColor: '#F5F5F5',
                },
                body: {
                    height: 40,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    paddingRight: 20,
                },
            },
        },
    });

export default theme;
