import {
    INIT_USER,
    SET_CGU,
    SET_ENEDIS_CONSENT_POPUP,
    SET_INITIALIZED,
    SET_IS_AUTHENTICATING,
    SET_USER,
} from './constants';
import { combineReducers } from 'redux';

function userReducer(state = null, action) {
    switch (action.type) {
        case SET_USER: {
            return action.user;
        }
        case INIT_USER: {
            return null;
        }
        case SET_INITIALIZED: {
            return { ...state.user, initialized: action.initialized };
        }
        default:
            return state;
    }
}

const cguInitialState = {
    open: false,
    showAcceptButton: true,
};

function cguReducer(state = cguInitialState, action) {
    switch (action.type) {
        case SET_CGU: {
            return action.cgu;
        }
        default:
            return state;
    }
}

function enedisConsentPopupReducer(state = false, action) {
    switch (action.type) {
        case SET_ENEDIS_CONSENT_POPUP: {
            return action.enedisConsentPopup;
        }
        default:
            return state;
    }
}

function isAuthenticatingReducer(state = true, action) {
    switch (action.type) {
        case SET_IS_AUTHENTICATING: {
            return action.isAuthenticating;
        }
        default:
            return state;
    }
}

export default combineReducers({
    user: userReducer,
    cgu: cguReducer,
    isAuthenticating: isAuthenticatingReducer,
    enedisConsentPopup: enedisConsentPopupReducer,
});
