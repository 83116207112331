const customDates = {
    lastDay: {
        label: 'Dernier jour',
        unit: 'days',
        value: 1,
    },
    lastWeek: {
        label: '7 derniers jours',
        unit: 'days',
        value: 7,
    },
    lastMonth: {
        label: 'Dernier mois',
        unit: 'month',
        value: 1,
    },
    lastSemester: {
        label: '6 derniers mois',
        unit: 'months',
        value: 6,
    },
    lastYear: {
        label: '12 derniers mois',
        unit: 'months',
        value: 12,
    },
};

const defaultPeriod = customDates.lastMonth;

const currVersion = 'v3.14.8';

const consumersTableColumns = [
    { id: 'name', label: 'Nom', width: '35%' },
    { id: 'prm', label: 'PRM', width: '25%' },
    { id: 'globalRate', label: 'Consommation', width: '15%' },
    { id: 'localRate', label: 'Autoproduction', width: '15%' },
    { id: 'details', label: '', width: '10%' },
];

const producersTableColumns = [
    { id: 'name', label: 'Nom', width: '35%' },
    { id: 'enedisId', label: 'PADT/PRM', width: '25%' },
    { id: 'globalRate', label: 'Production', width: '15%' },
    { id: 'localRate', label: 'Autoconsommation', width: '15%' },
    { id: 'details', label: '', width: '10%' },
];

const columnTypes = {
    BILLING: 'billingDetail',
    ENEDIS: 'enedisDetail',
};

const cityTableColumns = {
    postCode: '',
    cityName: '',
};
const contactTableColumns = {
    email: '',
    phoneNumber: '',
};
const participantsTableColumns = {
    [columnTypes.ENEDIS]: [
        {
            id: 'enedis_entityType',
            name: 'entityType',
            label: 'Type de client',
            minWidth: 150,
        },
        { id: 'enedis_name', name: 'name', label: 'Identité', minWidth: 150 },
        { id: 'enedis_prm', name: 'prm', label: 'N° du prm', minWidth: 120 },
        { id: 'enedis_padt', name: 'padt', label: 'N° du padt', minWidth: 120 },
        {
            id: 'enedis_activation_range_start',
            name: 'activationRangeStart',
            label: "Date d'entrée",
            minWidth: 120,
        },
        {
            id: 'enedis_activation_range_end',
            name: 'activationRangeEnd',
            label: 'Date de sortie (exclue)',
            minWidth: 120,
        },
        {
            id: 'enedis_address',
            name: 'address',
            label: 'Adresse',
            minWidth: 150,
        },
        {
            id: 'enedis_city',
            name: ['city', 'postCode'],
            label: 'ville',
            minWidth: 150,
        },
        {
            id: 'dossier_status',
            name: 'folder_status',
            label: 'Statut',
            minWidth: 100,
        },
        {
            id: 'account_status',
            name: 'account_status',
            label: 'Compte utilisateur',
            minWidth: 100,
        },
    ],
    [columnTypes.BILLING]: [
        { id: 'billing_name', name: 'name', label: 'Identité', minWidth: 150 },
        {
            id: 'billing_address',
            name: 'address',
            label: 'Adresse',
            minWidth: 150,
        },
        {
            id: 'billing_postCode',
            name: ['city', 'postCode'],
            label: 'ville',
            minWidth: 150,
        },
        {
            id: 'billing_phoneNumber',
            name: ['email', 'phoneNumber'],
            label: 'Contact',
            minWidth: 100,
        },
    ],
};

const userTypes = {
    CONSUMERS: 'consumers',
    PRODUCERS: 'producers',
};
const userTitle = {
    consumers: 'Consommateurs',
    producers: 'Producteurs',
};

const userUrl = {
    consumers: 'cons',
    producers: 'prod',
};

const entityTypes = [
    {
        value: 'Professionnel - industriel',
        label: 'Professionnel - industriel',
    },
    {
        value: 'Professionnel - tertiaire public',
        label: 'Professionnel - tertiaire public',
    },
    {
        value: 'Professionnel - tertiaire privé',
        label: 'Professionnel - tertiaire privé',
    },
    {
        value: 'Professionnel - artisan',
        label: 'Professionnel - artisan',
    },
    {
        value: 'Professionnel - agriculteur',
        label: 'Professionnel - agriculteur',
    },
    {
        value: 'Professionnel - autre',
        label: 'Professionnel - autre',
    },
    {
        value: 'Particulier - maison individuelle',
        label: 'Particulier - maison individuelle',
    },
    {
        value: 'Particulier - habitat collectif copropriété',
        label: "Particulier - habitat collectif copropriété'",
    },
    {
        value: 'Particulier - habitat collectif social',
        label: 'Particulier - habitat collectif social',
    },
    {
        value: 'Particulier - autre',
        label: 'Particulier - autre',
    },
];

const energyTypes = [
    {
        value: 'producer',
        label: 'Producteur',
    },
    {
        value: 'consumer',
        label: 'Consommateur',
    },
];

const drawerWidth = 300;
const closeDrawerWidth = 56;

const getSelectedRange = (selectedDates) => {
    const diff = selectedDates.to.diff(selectedDates.from, 'months', true);
    let selectedRange = 1;
    if (diff > 1 && diff <= 3) selectedRange = 3;
    else if (diff > 3 && diff <= 6) selectedRange = 6;
    else if (diff > 6 && diff <= 12) selectedRange = 12;
    return selectedRange;
};

const crumbTypes = {
    LINK: 'link',
    CONST: 'const',
    MENU: 'menu',
};

export {
    cityTableColumns,
    contactTableColumns,
    customDates,
    defaultPeriod,
    currVersion,
    consumersTableColumns,
    producersTableColumns,
    userTypes,
    userTitle,
    userUrl,
    entityTypes,
    energyTypes,
    drawerWidth,
    closeDrawerWidth,
    getSelectedRange,
    crumbTypes,
    participantsTableColumns,
    columnTypes,
};
