import {
    INIT_RANGE,
    INIT_SELECTED_DATES,
    INIT_IS_QUERYING_DATA_IN_RANGE,
    SET_RANGE,
    SET_SELECTED_DATES,
    SET_IS_QUERYING_DATA_IN_RANGE,
    RANGE_LOADING,
    SET_CDC_DATA,
    SET_METRICS_DATA,
    SET_CDC_LOADING,
    SET_METRICS_LOADING,
    INIT_MEASUREMENT,
    SET_DISTRIBUTION_DATA,
    SET_DISTRIBUTION_LOADING,
} from './constants';

const initialState = {
    filter: {
        selectedDates: {
            from: null,
            to: null,
        },
        range: {
            uploadedData: {
                from: null,
                to: null,
            },
            sgeTiersData: {
                from: null,
                to: null,
            },
        },
        loading: false,
    },
    cdc: {
        loading: false,
        data: null,
    },
    metrics: {
        loading: false,
        data: null,
    },
    distribution: {
        loading: false,
        data: null,
    },
    isQueryingDataInRange: null,
};

function measurementReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SELECTED_DATES: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    selectedDates: action.selectedDates,
                },
            };
        }

        case SET_RANGE: {
            return {
                ...state,
                filter: { ...state.filter, range: action.range },
            };
        }

        case SET_IS_QUERYING_DATA_IN_RANGE: {
            return {
                ...state,
                isQueryingDataInRange: action.isQueryingDataInRange,
            };
        }

        case RANGE_LOADING: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    loading: action.loading,
                },
            };
        }

        case INIT_SELECTED_DATES: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    selectedDates: {
                        from: null,
                        to: null,
                    },
                },
            };
        }

        case INIT_RANGE: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    range: {
                        uploadedData: {
                            from: null,
                            to: null,
                        },
                        sgeTiersData: {
                            from: null,
                            to: null,
                        },
                    },
                },
            };
        }

        case INIT_IS_QUERYING_DATA_IN_RANGE: {
            return {
                ...state,
                isQueryingDataInRange: null,
            };
        }

        case SET_DISTRIBUTION_DATA: {
            return {
                ...state,
                distribution: {
                    ...state.distribution,
                    data: action.distribution,
                },
            };
        }

        case SET_DISTRIBUTION_LOADING: {
            return {
                ...state,
                distribution: {
                    ...state.distribution,
                    loading: action.loading,
                },
            };
        }

        case SET_CDC_DATA: {
            return {
                ...state,
                cdc: {
                    ...state.cdc,
                    data: action.cdc,
                },
            };
        }

        case SET_CDC_LOADING: {
            return {
                ...state,
                cdc: {
                    ...state.cdc,
                    loading: action.loading,
                },
            };
        }

        case SET_METRICS_DATA: {
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    data: action.metrics,
                },
            };
        }

        case SET_METRICS_LOADING: {
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    loading: action.loading,
                },
            };
        }

        case INIT_MEASUREMENT: {
            return {
                filter: {
                    selectedDates: state.filter.selectedDates,
                    range: {
                        uploadedData: {
                            from: null,
                            to: null,
                        },
                        sgeTiersData: {
                            from: null,
                            to: null,
                        },
                    },
                },
                cdc: {
                    loading: false,
                    data: null,
                },
                metrics: {
                    loading: false,
                    data: null,
                },
                distribution: {
                    loading: false,
                    data: null,
                },
                isQueryingDataInRange: null,
            };
        }

        default:
            return state;
    }
}

export default measurementReducer;
